@import url('https://fonts.googleapis.com/css2?family=Graduate&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Graduate&display=swap');

#msd-container {
  background-image: url("/public/msd_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;

  font-family: 'Open Sans', sans-serif;
  color: black;
  text-align: center;
}

#logo {
  background-image: url("/public/msd_banner.webp");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 60px 0;
  z-index: -4;
  height: 512px;
  filter: drop-shadow(1px 1px 1px #333);
  position: absolute;
  bottom: 40px;
  width: 100%;
}

#auth-form-content {
  z-index: 0;
}

#auth-form {
  width: 596px;
  background-color: #f3f3ff;
  border-radius: 5px;
  opacity: 1;
  position: absolute;
  left: 50%;
  top: 58%;
  transform: translate(-50%, -40%);
  padding: 10px;
  border-top: 5px solid darkcyan;
  height: 360px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2), 0 3px 6px 0 rgba(0, 0, 0, 0.19);
}

@media only screen and (max-width: 596px) {
  #auth-form {
    width: 100%;
    height: 70%;
    bottom: 0;
    position: absolute;
    margin: 0;
    padding: 0;
  }

  body, html, #container {
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  #container {
    bottom: 0;
  }
}

@media only screen and (max-height: 400px) {
  #auth-form {
    top: 330px;
  }

  body {
    background-repeat: revert;
  }
}

h1 {
  font-size: 20px;
}

#privacy-policy {
  position: absolute;
  bottom: 20px;
}

#privacy-policy p {
  font-size: 12px;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

ul {
  display: inline-block;
  list-style: outside none none;
}

input[type="checkbox"] {
  width: 20px;
}

li {
  text-align: left;
}

a {
  background-color: darkcyan;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  text-align: center;
  text-decoration: none;
}

#submit {
  background-color: transparent;
  outline: 2px solid darkcyan;
  color: darkcyan;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  text-align: center;
  cursor: pointer;
}

#submit:hover {
  background-color: darkcyan;
  color: white;
}

#error {
  background-color: #c12828;
  width: 40%;
  height: 10%;
  border-radius: 5px;
  border: solid 2px black;
  color: white;
  margin: 10px;
  padding: 3px 10px;
  position: relative;
  transform: translateX(-50%);
  display: none;
  left: 50%;
}
